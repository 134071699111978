<script>
export default {
    data(){
        return{
            menus:[]
        }
    },
    created(){
        this.$store.dispatch('getMenus').then(res=>{
            this.menus = res
        })
    },
    mounted(){

    },
    methods:{
        handleClick(item){
            if(!item.path) return this.$message.error('菜单管理 `前端路由` 权限无配置')
            this.$router.push({name:item.path})
        }
    }
}
</script>

<template>
    <div class="sider">
        <overlay-scrollbars style="width: 100%;height: 100%;" class="el-fade-in">
            <el-menu
                :default-active="$route.meta.title"
                class="el-menu-vertical-demo"
                text-color="#B7B7B3"
                active-text-color="#ffffff"
                style="width:200px"
            >
                <el-menu-item :index="$win.mconfig.welcome">欢迎页</el-menu-item>
                <div v-for="item,index in menus" :key="index*10+10">
                    <el-menu-item :index="item.title" v-if="!item.children && item.isShow" @click="handleClick(item)">{{ item.title }}</el-menu-item>
                    <el-submenu :index="item.title" v-else-if="item.children && item.isShow">
                        <template slot="title">
                            <span>{{ item.title }}</span>
                        </template>
                        <div v-for="itx,inx in item.children" :key="inx*100+100">
                            <el-menu-item v-if="itx.type==2 && itx.isShow" :index="itx.title" @click="handleClick(itx)">{{ itx.title }}</el-menu-item>
                        </div>
                        
                    </el-submenu>
                </div>
                
                <!-- <el-menu-item index="/home">欢迎页</el-menu-item>
                <el-menu-item index="/expert">专家管理</el-menu-item>
                <el-menu-item index="/menus">菜单管理</el-menu-item>
                <el-submenu index="100">
                    <template slot="title">
                        <span>院校管理</span>
                    </template>
                    <el-menu-item index="/subject">学科管理</el-menu-item>
                    <el-menu-item index="/teacher">教师信息</el-menu-item>
                    <el-menu-item index="/source">教研资源</el-menu-item>
                </el-submenu>
                <el-submenu index="200">
                    <template slot="title">
                        <span>评课管理</span>
                    </template>
                    <el-menu-item index="/kpi">评课指标库</el-menu-item>
                    <el-menu-item index="/module">评课模板</el-menu-item>
                    <el-menu-item index="/31">评课报告</el-menu-item>
                    <el-menu-item index="/auto">人机协同评课</el-menu-item>
                    <el-menu-item>专家评课</el-menu-item>
                    <el-menu-item index="/33">教学反思</el-menu-item>
                </el-submenu>

                <el-submenu index="300">
                    <template slot="title">
                        <i class="el-icon-location"></i>
                        <span>系统设置</span>
                    </template>

                    <el-submenu index="1-4">
                        <template slot="title">选项4</template>
                        <el-menu-item index="1-4-1">选项1</el-menu-item>
                    </el-submenu>
                    
                    <el-menu-item index="/user">用户管理</el-menu-item>
                    <el-menu-item index="/dict">数据字典</el-menu-item>
                    <el-menu-item index="/role">角色管理</el-menu-item>

                </el-submenu> -->
                
                <!-- <el-submenu index="1">
                    <template slot="title">
                        <span>评课管理</span>
                    </template>

                    <el-submenu index="1-4">
                        <template slot="title">选项4</template>
                        <el-menu-item index="1-4-1">选项1</el-menu-item>
                    </el-submenu>
                    
                    <el-menu-item index="/user" @click="Tab({name:'用户管理',path:'/supplier'})">用户管理</el-menu-item>
                </el-submenu> -->
            </el-menu>
        </overlay-scrollbars>
    </div>
</template>

<style scoped lang="less">
@sider_w:200px; //侧边导航宽度
@sider_bg-color:#191A23; //侧边导航背景颜色
.sider {
    width: @sider_w;
    background-color: @sider_bg-color;
    position:absolute;
    left: 0;
    top: 0;
    bottom: 0;
    box-shadow:0 1px 4px rgb(0 21 41 / 8%);
    a {
        color: inherit;
    }
}
</style>